<template>
  <el-dialog
    v-model='dialogVisible'
    title='求租信息发布'
    width='65%'
    :before-close='handleClose'
  >
    <el-form :inline='true' :model='formInline' class='demo-form-inline'>
      <el-form-item label-width='70px' label='小区选择'>
        <el-select
          :remote-method='remoteMethod'
          filterable
          remote
          v-model='formInline.communityId'
          placeholder='请选择或输入小区'
        >
          <el-option
            v-for='item in searchData'
            :key='item.label'
            :label='item.label'
            :value='item.value'
            @click='optionClick(item.label)'
          />
        </el-select>
      </el-form-item>
      <el-form-item label-width='70px' label='求租户型'>
        <el-select v-model='formInline.roomNum' placeholder='请选择'>
          <el-option
            v-for='item in constant.layoutList'
            :key='item.label'
            :label='item.label'
            :value='item.value'
          />
        </el-select>
        <span class='houseLabel'>室</span>
        <el-select v-model='formInline.hallNum' placeholder='请选择'>
          <el-option
            v-for='item in constant.hallList'
            :key='item.label'
            :label='item.label'
            :value='item.value'
          />
        </el-select>
        <span class='houseLabel'>厅</span>
        <el-select v-model='formInline.toiletNum' placeholder='请选择'>
          <el-option
            v-for='item in constant.toiletList'
            :key='item.value'
            :label='item.label'
            :value='item.value'
          />
        </el-select>
        <span class='houseLabel'>卫</span>
      </el-form-item>
      <el-form-item label-width='70px' label='求租方式'>
        <el-select
          style='width: 350px'
          v-model='formInline.rentType'
          placeholder='请选择'
        >
          <el-option
            :label='item.label'
            :value='item.value'
            v-for='item in constant.rentTypeList'
            :key='item.value'
          />
        </el-select>
      </el-form-item>
      <el-form-item label-width='70px' label='求租区域'>
        <el-select
          style='width: 350px'
          v-model='formInline.districtId'
          @change='areaChange'
          placeholder='请选择'
        >
          <el-option
            :label='item.label'
            :value='item.value'
            v-for='item in constant.areaList'
            :key='item.value'
          />
        </el-select>
      </el-form-item>
      <el-form-item label-width='70px' label='租金范围'>
        <el-select
          style='width: 350px'
          v-model='formInline.rentPrice'
          placeholder='请选择'
        >
          <el-option
            :label='item.label'
            :value='item.value'
            v-for='item in constant.priceList'
            :key='item.value'
          />
        </el-select>
      </el-form-item>
      <el-form-item label-width='70px' label='装修情况'>
        <el-select
          style='width: 350px'
          v-model='formInline.situation'
          @change='situationChange'
          placeholder='请选择'
        >
          <el-option
            :label='item.label'
            :value='item.value'
            v-for='item in constant.situationList'
            :key='item.value'
          />
        </el-select>
      </el-form-item>
      <el-form-item label-width='70px' label='入住时间'>
        <el-date-picker
          style='width: 350px'
          v-model='formInline.upAt'
          type='date'
          :disabled-date='disabledDate'
          format='YYYY-MM-DD'
          value-format='YYYY-MM-DD hh:mm:ss '
          placeholder='请选择入住时间'
        />
      </el-form-item>
      <el-form-item label-width='70px' label='租赁时长'>
        <el-input
          style='width: 350px'
          v-model.number='formInline.duration'
          placeholder=''
        >
          <template #append>月</template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class='dialog-footer'>
        <el-button @click='cancel'>重置</el-button>
        <el-button type='primary' @click='add'>提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessageBox, ElMessage } from 'element-plus'
import {
  reactive,
  ref,
  onMounted,
  // computed,
  defineExpose,
  defineEmits
} from 'vue'
// import { useStore } from 'vuex'
import { findHouseList, addRentRelease } from '@/api/release'
import { deepCopy } from '@/utils/util'
import {
  AREA_MAP,
  HALL_MAP,
  LAYOUT_MAP,
  PRICE_MAP,
  RENT_TYPE_MAP,
  SITUATIONS_MAP,
  TOILET_MAP
} from '@/constant'
// const store = useStore()
import dayjs from 'dayjs'

const emit = defineEmits(['success'])
onMounted(() => {
})
// 当前角色
// const identity = computed(() => {
//   if (store.state.user.isTenant) {
//     return 1
//   } else {
//     return 0
//   }
// })
const getFormInline = () => {
  return {
    roomNum: '', // 室
    hallNum: '', // 厅
    toiletNum: '', // 卫
    rentType: '', // 求租方式
    districtId: '', // 区域编码
    locationName: '', // 区域名字
    rentPrice: '', // 租金范围
    situationName: '', // 装修情况
    situation: '',
    upAt: '', // 入住时间
    duration: '', // 租赁时长
    communityName: '',
    communityId: ''
  }
}

const rentAreaIndex = ref()

// 表单事件
const formInline = reactive(getFormInline())
const reset = () => {
  Object.assign(formInline, getFormInline())
}
const constant = reactive({
  areaList: deepCopy(AREA_MAP),
  rentTypeList: deepCopy(RENT_TYPE_MAP),
  priceList: deepCopy(PRICE_MAP),
  layoutList: deepCopy(LAYOUT_MAP),
  situationList: deepCopy(SITUATIONS_MAP),
  hallList: deepCopy(HALL_MAP),
  toiletList: deepCopy(TOILET_MAP)
})

// 入住时间只能选择将来的时间
function disabledDate(date) {
  return dayjs(date).isBefore(new Date())
}

//
function areaChange(value) {
  console.log(value)
  const items = AREA_MAP.filter((item) => item.value == value)
  if (items.length) {
    formInline.locationName = items[0].label
  } else {
    formInline.locationName = ''
  }
}

function situationChange(value) {
  console.log(value)
  const items = SITUATIONS_MAP.filter((item) => item.value == value)
  if (items.length) {
    formInline.situationName = items[0].label
  } else {
    formInline.situationName = ''
  }
}

const searchData = ref([])

async function remoteMethod(e) {
  const obj = {
    name: e
  }
  const res = await findHouseList(obj)
  console.log(res)
  searchData.value = res
}

//
function optionClick(e) {
  // console.log(e)
  formInline.communityName = e
}

// 添加
async function add() {
  console.log(formInline)
  try {
    const res = await addRentRelease(formInline)
    console.log(res)
    ElMessage({
      message: '添加成功',
      type: 'success'
    })
    emit('success')
    dialogVisible.value = false
    reset()
    rentAreaIndex.value = ''
  } catch (error) {
  } finally {
  }
}

// model事件
const dialogVisible = ref(false)
const handleClose = (done) => {
  ElMessageBox.confirm('您确定要取消当前操作并关闭对话框吗?')
    .then(() => {
      done()
      reset()
      rentAreaIndex.value = ''
    })
    .catch(() => {
      // catch error
    })
}
// 打开弹窗
const openModel = () => {
  dialogVisible.value = true
}

// 取消
function cancel() {
  // dialogVisible.value = false
  rentAreaIndex.value = ''
  reset()
}

defineExpose({
  openModel
})
</script>

<style lang='scss' scoped>
.dialog-footer button:first-child {
  margin-right: 10px;
}

.houseLabel {
  margin: 0 10px;
}

.titleBox {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  background-color: #f3f3f3;
  font-size: 14px;
  color: #333;
  border-radius: 2px;
  margin: 20px 0;
}

.textarea {
  margin: 10px 0;
}

.paginationStyle {
  width: 100%;
  height: 100px;
  background-color: #333;
  display: flex;
  justify-content: flex-end;
}
</style>
