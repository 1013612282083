import request from '@/utils/request'
/**
 * 查询求租发布列表
 */
export const findRentRelease = (data) => {
  return request({
    url: '/rentRelease/findRentRelease',
    method: 'POST',
    params: data
  })
}
/**
 * 查询求租发布列表
 */
export const remRentRelease = (data) => {
  return request({
    url: '/rentRelease/remRentRelease',
    method: 'DELETE',
    params: data
  })
}

/**
 * 查询房屋列表
 */
export const findHouseList = (data) => {
  return request({
    url: '/rentRelease/findHouseList',
    method: 'POST',
    params: data
  })
}
// 添加求组发布信息
export const addRentRelease = (data) => {
  return request({
    url: '/rentRelease/addRentRelease',
    method: 'POST',
    data
  })
}
