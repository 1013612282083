<template>
  <div style='height: 100%; width: 100%'>
    <nesi-user-common :title='`求租发布`'>
      <template #button>
        <div class='btn-wrapper'>
          <el-button type='primary' plain @click='open'>求租发布</el-button>
        </div>
      </template>
      <template #query>
        <el-table :data='tableData' style='width: 100%'>
          <el-table-column prop='community_name' label='标题'>
            <template #default='{ row }'>
              <span>求 </span>
              <span class='title-font'>
                {{ textDispose(row) }}
              </span>
              <span> 的房源</span>
            </template>
          </el-table-column>
          <el-table-column prop='rent_price' label='租金范围' width='160'>
            <template #default='{ row }'>
              <span v-if="row.rent_price && row.rent_price!='0'">
                {{
                  row.rent_price.split('_')[1]
                    ? row.rent_price.split('_').join('-')
                    : row.rent_price.replace('_', '')
                }}元
              </span>
              <span v-else>
                不限
              </span>

            </template>
          </el-table-column>
          <el-table-column prop='updated_at' label='发布时间' width='180' />
          <el-table-column
            prop='address'
            fixed='right'
            label='操作'
            width='120'
          >
            <template #default='{ row }'>
              <el-button
                link
                type='danger'
                size='small'
                @click='handleClick(row)'
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          :page-size='pageSize'
          v-model:current-page='pageNo'
          background
          layout='prev, pager, next'
          :total='total'
          @current-change='currentChange'
        />
      </template>
      <template #content></template>
    </nesi-user-common>
    <addModel @success='success' ref='model'></addModel>
  </div>
</template>

<script setup>
import NesiUserCommon from '@/components/NesiUserCommon/index.vue'
import addModel from './module/addModel.vue'
import { findRentRelease, remRentRelease } from '@/api/release.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, onMounted, defineComponent, computed } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
defineComponent({
  NesiUserCommon
})
onMounted(() => {
  getAppointment()
})

function textDispose(row) {
  const rentTypeCn = row.rent_type === 0 ? '不限' : row.rent_type === 2 ? '分租' : '整租'
  const item = row
  const title = `${item.location_name ? item.location_name : '不限区'} | ${item.community_name} | ${item.room_num}室${item.hall_num}厅${item.toilet_num}卫 | ${rentTypeCn} | ${item.situation_name ? item.situation_name : '其他'}`
  return title
}

// 当前角色
const identity = computed(() => {
  if (store.state.user.isTenant) {
    return 1
  } else {
    return 0
  }
})
const tableData = ref()
const pageNo = ref(1)
const pageSize = ref(10)
const total = ref()

// 分页
function currentChange() {
  getAppointment()
}

// 获取表单
async function getAppointment() {
  const obj = {
    num: identity.value,
    pageNo: pageNo.value,
    pageSize: pageSize.value
  }
  const res = await findRentRelease(obj)
  console.log(res)
  tableData.value = res.records
  total.value = res.total
}

// 添加成功事件
function success() {
  getAppointment()
}

// 删除
function handleClick(e) {
  console.log(e)
  ElMessageBox.alert('您确定要删除这条数据吗', '提示', {
    confirmButtonText: 'OK',
    callback: async (action) => {
      const obj = {
        id: e.id
      }
      try {
        await remRentRelease(obj)
        ElMessage({
          type: 'success',
          message: '删除成功！'
        })
        getAppointment()
      } catch (error) {
        ElMessage({
          type: 'error',
          message: '删除失败！'
        })
      }
    }
  })
}

// 添加表单模块
const model = ref(null)

function open() {
  model.value.openModel()
}
</script>

<style lang='scss' scoped>
.link {
  width: 70px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.required {
  color: #ff0000;
}

::v-deep .el-collapse-item__header {
  background: #f5f7fa;
  width: 100%;
  padding: 0 10px;
}

::v-deep .el-collapse-item__content {
  margin: 22px 0;
  padding: 0 10px;
}

.title-font {
  color: #268eff;
}

::v-deep .el-table {
  font-size: 13px;
}

.w-80 {
  width: 80px;
}

.w-120 {
  width: 120px;
}

.w-150 {
  width: 150px;
}

.indent-wrapper {
  p {
    margin-left: 20px;
  }
}

.w-p-48 {
  width: 48%;
}

::v-deep .el-dialog__body {
  padding-top: 0;
}
</style>
